import React from "react";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div
          className="py-4 px-2 vh-100 d-md-flex justify-content-md-center align-items-center"
          style={{
            padding: "1rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <section className="col-md-6 mx-auto">
            <h2 className="h4 lead">Something went wrong 😪</h2>
            <h1 className="error">Development Mode (Error Boundary)</h1>
            <details
              style={{ whiteSpace: "pre-wrap", height: "80vh", margin: "1rem" }}
              open
            >
              <summary>👇 This is what happened</summary>
              {this.state.error && this.state.error.toString()}
              <br />
              {this.state.errorInfo.componentStack}
            </details>
          </section>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
