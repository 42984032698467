// importing actions

import {
  FETCH_OWNER_INFO,
  FETCH_OWNER_TRANSACTIONS,
  FETCH_REPORT_SUMMARY,
  FETCH_TOP_PERFORMERS,
  FETCH_RECEIVED_BY_TIN,
  FETCH_RECORDERD_BY_TIN,
  FETCH_RECEIVED_BY_MONTH,
  FETCH_RECORDERD_BY_MONTH,
} from "../../types";

// state
// const INITIAL_STATE = {
//   performers: [],
//   reportSummary: {},
//   ownerInfo: {},
//   ownerTransactions: [],
//   recordedTin: {},
//   receivedTin: {},
//   recordedMonth: {},
//   receivedMonth: {},
// };

const initialState = {
  ownerTransactions: [],
  // Other state properties...
};

// Redux reducer function
// const reportReducer = (state = INITIAL_STATE, action) => {
//   // Destructuring action
//   const { type, payload } = action;

//   switch (type) {
//     case FETCH_TOP_PERFORMERS:
//       return {
//         ...state,
//         performers: payload,
//       };
//     case FETCH_REPORT_SUMMARY:
//       return {
//         ...state,
//         reportSummary: payload,
//       };
//     case FETCH_OWNER_INFO:
//       return {
//         ...state,
//         ownerInfo: payload,
//       };
//     case FETCH_OWNER_TRANSACTIONS:
//       return {
//         ...state,
//         ownerTransactions: payload,
//       };
//     case FETCH_RECEIVED_BY_TIN:
//       return {
//         ...state,
//         receivedTin: payload,
//       };
//     case FETCH_RECORDERD_BY_TIN:
//       return {
//         ...state,
//         recordedTin: payload,
//       };
//     case FETCH_RECEIVED_BY_MONTH:
//       return {
//         ...state,
//         receivedMonth: payload,
//       };
//     case FETCH_RECORDERD_BY_MONTH:
//       return {
//         ...state,
//         recordedMonth: payload,
//       };

//     default:
//       return state;
//   }
// };

const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_OWNER_TRANSACTIONS:
      return {
        ...state,
        ownerTransactions: action.payload,
      };
    // Other cases...
    default:
      return state;
  }
};
export default reportReducer;
