import NavBar from "../NavBar/NavBar.component";
import style from "./AppLayout.module.scss";
import Sidebar from "../Sidebar/Sidebar.component";
import { Outlet, Navigate } from "react-router-dom";
import { useLocalStorage } from "../../Hooks/useLocalStorage";

const AppLayout = () => {
  const { getLocalStorage } = useLocalStorage("userData");

  return getLocalStorage ? (
    <div className={style.app_layout}>
      <NavBar />
      <div className={style.app_layout_container}>
        <Sidebar />
        <div className={style.app_layout_container_contents} >
          <Outlet />
        </div>
      </div>
    </div>
  ) : (
    <Navigate to='login' replace />
  );
};

export default AppLayout;
