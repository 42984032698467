import * as React from "react";
import { NavLink as Nav } from "react-router-dom";
import { UtilContext } from "../../../Context/UtilContext";
import style from "./NavLink.module.scss";

const NavLink = ({ svgSrc, text, heading }) => {
  const { toggleHamburger } = React.useContext(UtilContext);

  return (
    <Nav
      to={
        heading
          ? `${
              heading.toLocaleLowerCase().replaceAll(" ", "-") +
              "/" +
              text.toLocaleLowerCase().replaceAll(" ", "-")
            }`
          : text.toLocaleLowerCase().replaceAll(" ", "-")
      }
      className={({ isActive }) =>
        isActive
          ? `${style.side_nav_link} ${style.active}`
          : style.side_nav_link
      }
      onClick={toggleHamburger}
    >
      <div className={style.side_nav_link_icon_cont}>
        <span className={style.side_nav_link_icon}>{svgSrc}</span>
      </div>
      <p className={style.side_nav_link_text}>{text}</p>
    </Nav>
  );
};

export default NavLink;
