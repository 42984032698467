import {
    FETCH_DAILY,
    FETCH_WEEKLY,
    FETCH_MONTHLY,
    FETCH_QUARTERLY,
    FETCH_YEARLY,
    FETCH_ALL_TRANSACTIONS_INSURANCE,
    FETCH_MARKET_SEGMENT_INSURANCE,
    FETCH_MONTHLY_PERFORMANCE_INSURANCE,
    FETCH_SUMMARY_INS
    
} from "../../types";


const INITIAL_STATE = {
    realDay: [],
    realWeek: [],
    realMonth: [],
    realQuarter: [],
    realYear: [],
    transaction: [],
    monthlySum: [],
    monthlyPerf: [],
    marketSeg: [],

};

const insuranceReducer = (state = INITIAL_STATE, action ) => {
    const { type, payload } = action;

    switch (type) {
        case FETCH_DAILY:
            return {
                ...state,
                realDay: payload,
            }

        case FETCH_WEEKLY:
            return {
                ...state,
                realWeek: payload,
            }

        case FETCH_MONTHLY:
            return {
                ...state,
                realMonth: payload,
            }
        
        case FETCH_QUARTERLY:
            return {
                ...state,
                realQuarter: payload,
            }

        case FETCH_YEARLY:
            return {
                ...state,
                realYear: payload,
            };

        case FETCH_ALL_TRANSACTIONS_INSURANCE:
            return {
                ...state,
                transaction: payload
            }

        case FETCH_MARKET_SEGMENT_INSURANCE:
            return {
                ...state,
                marketSeg: payload
            }
        
        case FETCH_MONTHLY_PERFORMANCE_INSURANCE:
            return {
                ...state,
                monthlyPerf: payload
            }

        case FETCH_SUMMARY_INS:
            return {
                ...state,
                monthlySum: payload
            }

            
        default:
            return state;
    }
};

export default insuranceReducer;
