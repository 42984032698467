import {
    FETCH_DAILY_DATA,
    FETCH_WEEKLY_DATA,
    FETCH_MONTHLY_DATA,
    FETCH_QUARTERLY_DATA,
    FETCH_YEARLY_DATA,
    FETCH_ALL_TRANSACTIONS_CAPITAL,
    FETCH_MARKET_SEGMENT_CAPITAL,
    FETCH_MONTHLY_PERFORMANCE_CAPITAL,
    FETCH_SUMMARY_CAP

} from '../../types';

const INITIAL_STATE = {
    exactDay: [],
    exactWeek: [],
    exactMonth: [],
    exactQuarter: [],
    exactYear: [],
    transaction: [],
    marketSeg: [],
    monthlyPerf: [],
    monthlySum: [],
};

const capitalReducer = (state = INITIAL_STATE, action) => {
    const { type, payload} = action;

    switch (type) {
        case FETCH_DAILY_DATA:
            return {
                ...state,
                exactDay: payload,
            }

        case FETCH_WEEKLY_DATA:
            return {
                ...state,
                exactWeek: payload,
            }
    
        case FETCH_MONTHLY_DATA:
            return {
                ...state,
                exactMonth: payload,
            }
    
        case FETCH_QUARTERLY_DATA:
            return {
                ...state,
                exactQuarter: payload,
            }
    

        case FETCH_YEARLY_DATA:
            return {
                ...state,
                exactYear: payload,
            }

        case FETCH_ALL_TRANSACTIONS_CAPITAL:
            return {
                ...state,
                transaction: payload
            }

        case FETCH_MARKET_SEGMENT_CAPITAL:
            return {
                ...state,
                marketSeg: payload
            }
        
        case FETCH_MONTHLY_PERFORMANCE_CAPITAL:
            return {
                ...state,
                monthlyPerf: payload
            }

        case FETCH_SUMMARY_CAP:
            return {
                ...state,
                monthlySum: payload
            }
    
        default:
            return state;
    }
};

export default capitalReducer;