import { combineReducers } from "redux";
import userReducer from "./user/reducers/user.reducers";
import recordReducer from "./records/reducers/record.reducers";
import transactionReducer from "./transaction/reducers/transaction.reducers";
import dashboardReducer from "./dashboard/reducers/dashboard.reducers";
import insuranceReducer from "./insurance/reducers/insurance.reducer";
import capitalReducer from "./CapitalMarket/reducers/capital.reducers";
import subInsuranceReducer from "./sub-insurance/reducers/subInsurace.reducers";
import subCapitalReducer from "./sub-capital/reducers/subCapital.reducers";
import reportReducer from "./reports/reducers/report.reducers";

const rootReducer = combineReducers({
  userReducer,
  recordReducer,
  dashboardReducer,
  capitalReducer,
  insuranceReducer,
  transactionReducer,
  subCapitalReducer,
  subInsuranceReducer,
  reportReducer,
});

export default rootReducer;
