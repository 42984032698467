import {
    FETCH_DAILY_NAICOM,
    FETCH_WEEKLY_NAICOM,
    FETCH_MONTHLY_NAICOM,
    FETCH_QUARTERLY_NAICOM,
    FETCH_YEARLY_NAICOM,
    FETCH_TRANSACTIONS_INS_NAICOM,
    FETCH_NAICOM_VAT_BY_HOUR,
    FETCH_NAICOM_QUARTERLY_DATA,


    FETCH_DAILY_PENSION,
    FETCH_WEEKLY_PENSION,
    FETCH_MONTHLY_PENSION,
    FETCH_QUARTERLY_PENSION,
    FETCH_YEARLY_PENSION,
    FETCH_TRANSACTIONS_INS_PENSION,
    FETCH_PENSION_VAT_BY_HOUR,
    FETCH_PENSION_QUARTERLY_DATA,


} from '../../types';

const INITIAL_STATE = {
    naicomDay: [],
    naicomWeek: [],
    naicomMonth: [],
    naicomQuarter: [],
    naicomYear: [],
    transaction: [],
    naicomVatByHour: [],
    quarterlyNaicom: [],

    pensionDay: [],
    pensionWeek: [],
    pensionMonth: [],
    pensionQuarter: [],
    pensionYear: [],
    // transaction: [],
    pensionVatByHour: [],
    quartrlyPension: [],

};

const subInsuranceReducer = ( state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case FETCH_DAILY_NAICOM:
            return {
                ...state,
                naicomDay: payload,
            }
        
        
        case FETCH_WEEKLY_NAICOM:
            return {
                ...state,
                naicomWeek: payload,
            }

        case FETCH_MONTHLY_NAICOM:
            return {
                ...state,
                naicomMonth: payload,
            }

        case FETCH_QUARTERLY_NAICOM:
            return {
                ...state,
                naicomQuarter: payload,
            }

        case FETCH_YEARLY_NAICOM:
            return {
                ...state,
                naicomYear: payload,
            }

        case FETCH_TRANSACTIONS_INS_NAICOM:
            return {
                ...state,
                transaction: payload,
            }

        case FETCH_NAICOM_VAT_BY_HOUR:
            return {
                ...state,
                naicomVatByHour: payload,
            };

        case FETCH_NAICOM_QUARTERLY_DATA:
            return {
                ...state,
                quarterlyNaicom: payload,

            }
    
        /*
           PENSION
        */


        case FETCH_DAILY_PENSION:
            return {
                ...state,
                pensionDay: payload,
            }
        
        
        case FETCH_WEEKLY_PENSION:
            return {
                ...state,
                pensionWeek: payload,
            }

        case FETCH_MONTHLY_PENSION:
            return {
                ...state,
                pensionMonth: payload,
            }

        case FETCH_QUARTERLY_PENSION:
            return {
                ...state,
                pensionQuarter: payload,
            }

        case FETCH_YEARLY_PENSION:
            return {
                ...state,
                pensionYear: payload,
            };

        case FETCH_PENSION_VAT_BY_HOUR:
            return {
                ...state,
                pensionVatByHour: payload,
            };

        case FETCH_TRANSACTIONS_INS_PENSION:
            return {
                ...state,
                transaction: payload,
            }

        case FETCH_PENSION_QUARTERLY_DATA:
            return {
                ...state,
                quarterlyPension: payload,

            }

        default:
            return state;
}
};

export default subInsuranceReducer;
