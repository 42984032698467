// importing actions

import {
  FETCH_ACTUAL_DAY,
  FETCH_ACTUAL_HOUR,
  FETCH_ACTUAL_MONTH,
  FETCH_ACTUAL_QUARTER,
  FETCH_ACTUAL_YEAR,
  FETCH_YEARLY_SUMMARY,
  FETCH_ALL_TRANSACTIONS_DASHBOARD,
  FETCH_MONTHLY_SUMMARY,
  FETCH_YEARLY_VAT,
  FETCH_VAT_BY_HOUR,
  FETCH_YEARLY_VAT_THRESHOLD,
  FETCH_YEARLY_VAT_REGION
} from "../../types";

// state
const INITIAL_STATE = {
  actualYear: [],
  actualQuarter: [],
  actualMonth: [],
  actualDay: [],
  actualHour: [],
  yearlySummary: [],
  transactions: [],
  monthlySummary: [],
  yearlyVat: [],
  vatByHour: [],
  vatThreshold: [],
  vatRegion: []
};

// Redux reducer function
const dashboardReducer = (state = INITIAL_STATE, action) => {
  // Destructuring action
  const { type, payload } = action;

  switch (type) {
    case FETCH_ACTUAL_YEAR:
      return {
        ...state,
        actualYear: payload,
      };
    case FETCH_ACTUAL_QUARTER:
      return {
        ...state,
        actualQuarter: payload,
      };
    case FETCH_ACTUAL_MONTH:
      return {
        ...state,
        actualMonth: payload,
      };
    case FETCH_ACTUAL_DAY:
      return {
        ...state,
        actualDay: payload,
      };
    case FETCH_ACTUAL_HOUR:
      return {
        ...state,
        actualHour: payload,
      };
    case FETCH_YEARLY_SUMMARY:
      return {
        ...state,
        yearlySummary: payload,
      };
    case FETCH_ALL_TRANSACTIONS_DASHBOARD:
      return {
        ...state,
        transactions: payload,
      };
    case FETCH_MONTHLY_SUMMARY:
      return {
        ...state,
        monthlySummary: payload,
      };
    case FETCH_YEARLY_VAT:
      return {
        ...state,
        yearlyVat: payload,
      };
    case FETCH_VAT_BY_HOUR:
      return {
        ...state,
        vatByHour: payload,
      };

    case FETCH_YEARLY_VAT_REGION:
      return {
        ...state,
        vatRegion: payload,
      };

    case FETCH_YEARLY_VAT_THRESHOLD:
      return{
        ...state,
        vatThreshold: payload
      };
    default:
      return state;
  }
};

export default dashboardReducer;
