import { BsArrowRight } from "react-icons/bs";

const color = "444";

export const capitalMarketLinks = [
  {
    svgSrc: <BsArrowRight color={color} />,
    text: "SEC",
  },
  {
    svgSrc: <BsArrowRight color={color} />,
    text: "CSCS",
  },
  {
    svgSrc: <BsArrowRight color={color} />,
    text: "NGX",
  },
  {
    svgSrc: <BsArrowRight color={color} />,
    text: "Stock Brokers",
  },
];

export const insuranceLinks = [
  {
    svgSrc: <BsArrowRight color={color} />,
    text: "Naicom",
  },
  {
    svgSrc: <BsArrowRight color={color} />,
    text: "Pension",
  },
  // {
  //   svgSrc: <BsArrowRight color={color} />,
  //   text: "Lorem",
  // },
  // {
  //   svgSrc: <BsArrowRight color={color} />,
  //   text: "Ipsum",
  // }
];

export const recordLink = [
  {
    svgSrc: <BsArrowRight color={color} />,
    text: "Company",
  },
  {
    svgSrc: <BsArrowRight color={color} />,
    text: "Individual",
  },
  // {
  //   svgSrc: <BsArrowRight color={color} />,
  //   text: "Lorem",
  // },
  // {
  //   svgSrc: <BsArrowRight color={color} />,
  //   text: "Ipsum",
  // }
];
