import React, { useEffect, useState } from "react";
import NavLink from "./NavLink/NavLink.component";
import style from "./Sidebar.module.scss";
import { UtilContext } from "../../Context/UtilContext";
import NavLinkCategory from "./NavLinkCategory/NavLinkCategory.component";
import {
  capitalMarketLinks,
  insuranceLinks,
  recordLink,
} from "../../utils/data/sidebar.data";
import { ReactComponent as Analytics } from "../../assets/svg/analytics.svg";
import { ReactComponent as Report } from "../../assets/svg/reports.svg";
import { ReactComponent as Bank } from "../../assets/svg/bankCard.svg";
import { ReactComponent as Insurance } from "../../assets/svg/marketo.svg";
import { ReactComponent as Dashboard } from "../../assets/svg/performance.svg";
import { ReactComponent as NegotiatedDeal } from "../../assets/svg/marketo.svg";
import { ReactComponent as Stock } from "../../assets/svg/stockMarket.svg";
import { ReactComponent as Contact } from "../../assets/svg/contact.svg";
import { ReactComponent as User } from "../../assets/svg/user.svg";
import { BiLogOutCircle } from "react-icons/bi";
import { useLocalStorage } from "../../Hooks/useLocalStorage";


const Sidebar = () => {
  const [hideElement, setHideElement] = useState(false);
  const { isMobile, showHamburger } = React.useContext(UtilContext);

  const { getLocalStorage } = useLocalStorage("userData");
  const user = JSON.parse(getLocalStorage);

  useEffect(() => {
    if (user?.userType === 'viewer') {
      setHideElement(true);
    } else {
      setHideElement(false);
    }
  }, [user]);

  const { removeLocalStorage } = useLocalStorage("userData");

  const handleLogout = () => {
    removeLocalStorage();
    window.location.href = "/login";
  };

  return (
    <div
      className={`${style.sidebar} ${
        isMobile && showHamburger ? style.show_sidebar : ""
      }`}
    >
      <div className={style.sidebar_contents}>
  {user?.userType === 'company' ? (
    <>
      <NavLink svgSrc={<NegotiatedDeal />} text='Negotiated Deal' />
      {/* <NavLink svgSrc={<Report />} text='Report' /> */}
      <NavLink svgSrc={<Report />} text='Company Report' />
    </>
  ) : user?.userType === 'viewer' ? (
    <>
      <NavLink svgSrc={<Dashboard />} text='Dashboard' />
      <NavLink svgSrc={<Report />} text='Report' />
      <NavLinkCategory
        heading={"Capital Market"}
        options={capitalMarketLinks}
        svgSrc={<Stock />}
      />
    </>
  ) : (
    <>
      <NavLink svgSrc={<Dashboard />} text='Dashboard' />
      <NavLinkCategory
        heading={"Capital Market"}
        options={capitalMarketLinks}
        svgSrc={<Stock />}
      />
      <NavLink svgSrc={<Report />} text='Report' />
      {!hideElement && <NavLink svgSrc={<Contact />} text='Contact' />}
      {!hideElement && <NavLink svgSrc={<User />} text='Users' />}

    </>
  )}
</div>


      <div className={style.sidebar_logout} onClick={handleLogout}>
        <div className={style.sidebar_logout_icon_cont}>
          <span className={style.sidebar_logout_icon}>
            <BiLogOutCircle color={"#000"} style={style} />
          </span>
        </div>
        <p className={style.sidebar_logout_text}>Logout</p>
      </div>
    </div>
  );
};

export default Sidebar;
