import * as React from "react";
import { useMediaQuery } from "../Hooks/useMediaQuery";

export const UtilContext = React.createContext();

export const UtilProvider = ({ children }) => {
  const [showHamburger, setShowHamburger] = React.useState(false);
  const isMobile = useMediaQuery();

  // toggle hamburger on smaller screens
  const toggleHamburger = () => {
    setShowHamburger((prevS) => !prevS);

    setTimeout(() => {
      if (!showHamburger && isMobile) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    }, 100);
  };

  return (
    <UtilContext.Provider
      value={{
        toggleHamburger,
        showHamburger,
        isMobile,
      }}
    >
      {children}
    </UtilContext.Provider>
  );
};
