// importing actions

import {
  FETCH_ALL_TRANSACTIONS,
  FETCH_ALL_TRANSACTIONS_DATES,
  FETCH_ALL_TRANSACTIONS_REGION,
  FETCH_ALL_TRANSACTIONS_SECTOR,
  FETCH_ALL_TRANSACTIONS_SECTOR_DATES,
  FETCH_ALL_TRANSACTIONS_STATE,
  FETCH_ALL_TRANSACTIONS_SUBSECTOR,
  FETCH_ALL_TRANSACTIONS_SUBSECTOR_DATES,
  FETCH_ALL_TRANSACTIONS_TIN,
} from "../../types";

// state
const INITIAL_STATE = {
  transactions: [],
  region: [],
  stateTransaction: [],
  tinTransaction: [],
  dateTransaction: [],
  sector: [],
  subSector: [],
  sectorWithDate: [],
  subSectorWithDate: [],
};

// Redux reducer function
const transactionReducer = (state = INITIAL_STATE, action) => {
  // Destructuring action
  const { type, payload } = action;

  switch (type) {
    case FETCH_ALL_TRANSACTIONS:
      return {
        ...state,
        transactions: payload,
      };
    case FETCH_ALL_TRANSACTIONS_REGION:
      return {
        ...state,
        region: payload,
      };
    case FETCH_ALL_TRANSACTIONS_STATE:
      return {
        ...state,
        stateTransaction: payload,
      };
    case FETCH_ALL_TRANSACTIONS_TIN:
      return {
        ...state,
        tinTransaction: payload,
      };
    case FETCH_ALL_TRANSACTIONS_DATES:
      return {
        ...state,
        dateTransaction: payload,
      };
    case FETCH_ALL_TRANSACTIONS_SECTOR:
      return {
        ...state,
        sector: payload,
      };
    case FETCH_ALL_TRANSACTIONS_SECTOR_DATES:
      return {
        ...state,
        sectorWithDate: payload,
      };
    case FETCH_ALL_TRANSACTIONS_SUBSECTOR:
      return {
        ...state,
        subSector: payload,
      };
    case FETCH_ALL_TRANSACTIONS_SUBSECTOR_DATES:
      return {
        ...state,
        subSectorWithDate: payload,
      };
    default:
      return state;
  }
};

export default transactionReducer;
