import React from "react";
import { useNavigate } from "react-router-dom";
import { useClickOutside } from "../../../Hooks/useClickOutside";
import NavLink from "../NavLink/NavLink.component";
import style from "./NavLinkCategory.module.scss";

const NavLinkCategory = ({ heading, options, svgSrc }) => {
  const { visible, ref, setVisible } = useClickOutside();

  const navigate = useNavigate();

  const handleMenu = () => {
    navigate(heading.toLocaleLowerCase().replaceAll(" ", "-"));
    setVisible(!visible);
  };

  return (
    <div className={style.nav_link_category} ref={ref}>
      <div className={style.nav_link_category_head} onClick={handleMenu}>
        <span className={style.nav_link_category_head_icon}>{svgSrc}</span>
        <p className={style.nav_link_category_head_heading}>{heading}</p>
      </div>
      {visible &&
        options.map(({ svgSrc, text }) => (
          <div className={style.nav_link_category_sub}>
            <NavLink heading={heading} key={text} svgSrc={svgSrc} text={text} />
          </div>
        ))}
    </div>
  );
};

export default NavLinkCategory;
