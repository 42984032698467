// importing actions

import { 
  LOGIN_SUCCESS,
   SIGNUP_SUCCESS,
  /*SIGNUP_VERIFICATION_SUCCESS,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_REQUEST_SUCCESS, */
  GET_USERS_SUCCESS,
  GET_ONE_USER_SUCCESS,
  /* LOCK_USER_SUCCESS,
  UPLOAD_PASSPORT_SUCCESS */

 } from "../../types";

// state
const INITIAL_STATE = {
  userData: {},
  isLoggedIn: false,
  getUsers : [],
  getUser : [],
  response: [],

};

// Redux reducer function
const userReducer = (state = INITIAL_STATE, action) => {
  // Destructuring action
  const { type, payload } = action;

  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        userData: payload,
        isLoggedIn: true,
      };

    case SIGNUP_SUCCESS:
      return {
        ...state,
        response: payload
      };

    /* case SIGNUP_VERIFICATION_SUCCESS:
      return {
        ...state,
      }

    case PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
      }

    case PASSWORD_RESET_SUCCESS:
      return {
        ...state,
      } */

    case GET_USERS_SUCCESS:
      return {
        ...state,
        getUsers : payload,
      }

      case GET_ONE_USER_SUCCESS:
      return {
        ...state,
        getUser : payload,
      }

    default:
      return state;
  }
};

export default userReducer;
