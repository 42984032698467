import {
  FETCH_DAILY_SEC,
  FETCH_WEEKLY_SEC,
  FETCH_MONTHLY_SEC,
  FETCH_QUARTERLY_SEC,
  FETCH_YEARLY_SEC,
  FETCH_TRANSACTIONS_CAP_SEC,
  FETCH_SEC_VAT_BY_HOUR,
  FETCH_SEC_QUARTERLY_DATA,
  FETCH_DAILY_CSCS,
  FETCH_WEEKLY_CSCS,
  FETCH_MONTHLY_CSCS,
  FETCH_QUARTERLY_CSCS,
  FETCH_YEARLY_CSCS,
  FETCH_TRANSACTIONS_CAP_CSCS,
  FETCH_CSCS_VAT_BY_HOUR,
  FETCH_CSCS_QUARTERLY_DATA,
  FETCH_DAILY_NGX,
  FETCH_WEEKLY_NGX,
  FETCH_MONTHLY_NGX,
  FETCH_QUARTERLY_NGX,
  FETCH_YEARLY_NGX,
  FETCH_TRANSACTIONS_CAP_NGX,
  FETCH_NGX_VAT_BY_HOUR,
  FETCH_NGX_QUARTERLY_DATA,
  FETCH_DAILY_FMDQ,
  FETCH_WEEKLY_FMDQ,
  FETCH_MONTHLY_FMDQ,
  FETCH_QUARTERLY_FMDQ,
  FETCH_YEARLY_FMDQ,
  FETCH_TRANSACTIONS_CAP_FMDQ,
  FETCH_FMDQ_VAT_BY_HOUR,
  FETCH_FMDQ_QUARTERLY_DATA,
} from "../../types";

const INITIAL_STATE = {
  secDay: [],
  secWeek: [],
  secMonth: [],
  secQuarter: [],
  secYear: [],
  transaction: [],
  secVatByHour: [],
  quarterlySec: [],

  cscsDay: [],
  cscsWeek: [],
  cscsMonth: [],
  cscsQuarter: [],
  cscsYear: [],
  cscsVatByHour: [],
  quarterlyCscs: [],

  ngxDay: [],
  ngxWeek: [],
  ngxMonth: [],
  ngxQuarter: [],
  ngxYear: [],
  ngxVatByHour: [],
  quarterlyNgx: [],

  fmdqDay: [],
  fmdqWeek: [],
  fmdqMonth: [],
  fmdqQuarter: [],
  fmdqYear: [],
  fmdqVatByHour: [],
  quarterlyFmdq: [],
};

const subCapitalReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_DAILY_SEC:
      return {
        ...state,
        secDay: payload,
      };

    case FETCH_WEEKLY_SEC:
      return {
        ...state,
        secWeek: payload,
      };

    case FETCH_MONTHLY_SEC:
      return {
        ...state,
        secMonth: payload,
      };

    case FETCH_QUARTERLY_SEC:
      return {
        ...state,
        secQuarter: payload,
      };

    case FETCH_YEARLY_SEC:
      return {
        ...state,
        secYear: payload,
      };

    case FETCH_TRANSACTIONS_CAP_SEC:
      return {
        ...state,
        transaction: payload,
      };

    case FETCH_SEC_VAT_BY_HOUR:
      return {
        ...state,
        secVatByHour: payload,
      };

    case FETCH_SEC_QUARTERLY_DATA:
      return {
        ...state,
        quarterlySec: payload,
      };

    /*
            CSCS
        */

    case FETCH_DAILY_CSCS:
      return {
        ...state,
        cscsDay: payload,
      };

    case FETCH_WEEKLY_CSCS:
      return {
        ...state,
        cscsWeek: payload,
      };

    case FETCH_MONTHLY_CSCS:
      return {
        ...state,
        cscsMonth: payload,
      };

    case FETCH_QUARTERLY_CSCS:
      return {
        ...state,
        cscsQuarter: payload,
      };

    case FETCH_YEARLY_CSCS:
      return {
        ...state,
        cscsYear: payload,
      };

    case FETCH_TRANSACTIONS_CAP_CSCS:
      return {
        ...state,
        transaction: payload,
      };

    case FETCH_CSCS_VAT_BY_HOUR:
      return {
        ...state,
        cscsVatByHour: payload,
      };

    case FETCH_CSCS_QUARTERLY_DATA:
      return {
        ...state,
        quarterlyCscs: payload,
      };

    /*
     * NGX
     */

    case FETCH_DAILY_NGX:
      return {
        ...state,
        ngxDay: payload,
      };

    case FETCH_WEEKLY_NGX:
      return {
        ...state,
        ngxWeek: payload,
      };

    case FETCH_MONTHLY_NGX:
      return {
        ...state,
        ngxMonth: payload,
      };

    case FETCH_QUARTERLY_NGX:
      return {
        ...state,
        ngxQuarter: payload,
      };

    case FETCH_YEARLY_NGX:
      return {
        ...state,
        ngxYear: payload,
      };

    case FETCH_TRANSACTIONS_CAP_NGX:
      return {
        ...state,
        transaction: payload,
      };

    case FETCH_NGX_VAT_BY_HOUR:
      return {
        ...state,
        ngxVatByHour: payload,
      };

    case FETCH_NGX_QUARTERLY_DATA:
      return {
        ...state,
        quarterlyNgx: payload,
      };

    /*
     * FMDQ
     */
    case FETCH_DAILY_FMDQ:
      return {
        ...state,
        fmdqDay: payload,
      };

    case FETCH_WEEKLY_FMDQ:
      return {
        ...state,
        fmdqWeek: payload,
      };

    case FETCH_MONTHLY_FMDQ:
      return {
        ...state,
        fmdqMonth: payload,
      };

    case FETCH_QUARTERLY_FMDQ:
      return {
        ...state,
        fmdqQuarter: payload,
      };

    case FETCH_YEARLY_FMDQ:
      return {
        ...state,
        fmdqYear: payload,
      };

    case FETCH_TRANSACTIONS_CAP_FMDQ:
      return {
        ...state,
        transaction: payload,
      };

    case FETCH_FMDQ_VAT_BY_HOUR:
      return {
        ...state,
        fmdqVatByHour: payload,
      };

    case FETCH_FMDQ_QUARTERLY_DATA:
      return {
        ...state,
        quarterlyFmdq: payload,
      };

    default:
      return state;
  }
};

export default subCapitalReducer;
