export const useLocalStorage = (key) => {
  // setting data to local storage
  const setLocalStorage = (userData) => localStorage.setItem(key, userData);

  // get data from local storage
  const getLocalStorage = localStorage.getItem(key);

  //   remove data from local storage
  const removeLocalStorage = () => localStorage.removeItem(key);

  return { setLocalStorage, getLocalStorage, removeLocalStorage };
};
