import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useLocation } from "react-router-dom";

const Desc = ({ title, description, keywords }) => {
  const { pathname } = useLocation();
  const newPath = pathname.split("/")[1].toLocaleUpperCase();
  return (
    <HelmetProvider>
      <Helmet>
        <title>{`${newPath === "" ? "HOME" : newPath} | FIRS`}</title>
        <meta name={`${newPath}...`} content={description} />
      </Helmet>
    </HelmetProvider>
  );
};

export default Desc;
