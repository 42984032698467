import * as React from "react";
import { UtilContext } from "../../../Context/UtilContext";
import style from "./MobileHamburger.module.scss";

const MobileHamburger = () => {
  const { showHamburger, toggleHamburger } = React.useContext(UtilContext);

  return (
    <label htmlFor='check' className={style.bar} onClick={toggleHamburger}>
      <span
        className={`${style.top} ${showHamburger ? style.transform : ""}`}
      ></span>
      <span
        className={`${style.middle} ${showHamburger ? style.transform : ""}`}
      ></span>
      <span
        className={`${style.bottom} ${showHamburger ? style.transform : ""}`}
      ></span>
    </label>
  );
};

export default MobileHamburger;
